import { Link } from '@remix-run/react'
import { Icon } from './ui/icon.tsx'

function BlockOfLinks({ children, title }: { children: any; title: string }) {
	return (
		<div className="flex flex-col gap-4">
			<span className="text-h6">{title}</span>

			<div className="flex flex-col gap-2">{children}</div>
		</div>
	)
}

export function Footer() {
	return (
		<footer className="block w-full pt-48" id="footer">
			<div className="container">
				<div className="flex flex-col gap-20 sm:flex-row">
					<div className="flex flex-col gap-1">
						<div className="flex flex-row items-center gap-2">
							<Icon name="logo" className="h-10 w-10" />
							<b className="mt-1 bg-clip-text text-h6">
								BookInsights
							</b>
						</div>
						<div className="text-sm opacity-30">
							© Copyright {new Date().getFullYear()} All Rights <br />
							Reserved by Luisiacc LLC.
						</div>
					</div>
					<BlockOfLinks title="Pages">
						<Link prefetch="intent" to="/login" className="text-sm opacity-80">
							Login
						</Link>
						<a
							// eslint-disable-next-line remix-react-routes/use-link-for-routes
							href="mailto:support@bookinsightsai.com"
							className="text-sm opacity-80"
						>
							Contact support
						</a>
						<Link to="/plans" className="text-sm opacity-80">
							Pricing
						</Link>
						<Link to="/#faqs" className="text-sm opacity-80">
							FAQ
						</Link>
					</BlockOfLinks>
					<BlockOfLinks title="Legal">
						<Link to="/tos" className="text-sm opacity-80">
							Terms of Service
						</Link>
						<Link to="/privacy" className="text-sm opacity-80">
							Privacy Policy
						</Link>
					</BlockOfLinks>
				</div>
			</div>
			<div className="h-10" />
		</footer>
	)
}
